import { Reducer } from 'redux';
import { Action } from 'redux-actions';

import { produceReducer } from '../../common/utils/immer';

import { ACTIONS } from './actions';
import { Product } from './types';

export interface PaymentState {
    product?: Product;
}

const initialState: PaymentState = {
    product: undefined,
};

const actionHandlers = {
    [ACTIONS.GET_PRICES_SUCCESS]: (draftStore: PaymentState, action: Action<Product>) => {
        draftStore.product = action.payload;
    },
};

export default produceReducer(actionHandlers, initialState) as Reducer<Product>;
