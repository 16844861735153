/* default variables color*/
export const styles = {
    $gray_300: '#f0f0f0',
    $danger: '#b91c1c',
    $primary_normal: '#3a7ab1',
    $shadow_error: '0px 0px 0px 3px #fee2e2',
    $shadow_focused: '0px 0px 0px 3px rgba(58, 122, 177, 0.4)',
    $primary_pale: '#dee9f2',
    $primary: '#0e4486',
    $white: '#fff',
    $gray_700: '#757575',
    $webkit_autofill: '#fce883',
};
