import classNames from 'classnames';
import React from 'react';

import './LoadingStyles.scss';

export enum LoadingSize {
    XS = 'XS',
    SM = 'SM',
    MD = 'MD',
    LG = 'LG',
    XL = 'XL',
}

export enum LoadingType {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
    LIGHT = 'LIGHT',
}

interface LoadingProps {
    size?: LoadingSize;
    type?: LoadingType;
}

const Loading: React.FunctionComponent<LoadingProps> = ({ size = LoadingSize.SM, type }) => {
    const classes = classNames('spinner-rolling', {
        'spinner-rolling--xs': size === LoadingSize.XS,
        'spinner-rolling--sm': size === LoadingSize.SM,
        'spinner-rolling--md': size === LoadingSize.MD,
        'spinner-rolling--lg': size === LoadingSize.LG,
        'spinner-rolling--xl': size === LoadingSize.XL,
    });

    const iconClass = classNames('spinner-icon', {
        'spinner-icon--xs': size === LoadingSize.XS,
        'spinner-icon--sm': size === LoadingSize.SM,
        'spinner-icon--md': size === LoadingSize.MD,
        'spinner-icon--lg': size === LoadingSize.LG,
        'spinner-icon--xl': size === LoadingSize.XL,
        'spinner-icon--primary': type === LoadingType.PRIMARY,
        'spinner-icon--secondary': type === LoadingType.SECONDARY,
        'spinner-icon--light': type === LoadingType.LIGHT,
    });

    return (
        <div className={classes}>
            <div className={iconClass}>
                <div />
            </div>
        </div>
    );
};

export default Loading;
