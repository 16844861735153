import { Reducer } from 'redux';
import { Action } from 'redux-actions';

import { produceReducer } from '../../common/utils/immer';

import { ACTIONS } from './actions';

export interface AuthState {
    messsage: string;
}

const initialState: AuthState = {
    messsage: '',
};

const actionHandlers = {
    [ACTIONS.SET_AUTH_MESSAGE]: (draftStore: AuthState, action: Action<string>) => {
        draftStore.messsage = action.payload;
    },
};

export default produceReducer(actionHandlers, initialState) as Reducer<AuthState>;
