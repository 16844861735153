import { AuthErrorCode } from '../constants/enum';

export enum FirebaseFunction {
    SearchUser = 'searchUser',
    requestResetPasswordOnWeb = 'sendResetPassword',
    PayWithStripe = 'payWithStripe',
    CancelSubscription = 'cancelSubscription',
}

export const getAuthErrorMessage = (code: AuthErrorCode) => {
    switch (code) {
        case AuthErrorCode.AlreadyInUse:
            return 'This email is already in use';
        case AuthErrorCode.NoUserFound:
            return "User doesn't exist";
        case AuthErrorCode.WrongPassword:
            return 'Email or password is incorrect';
        case AuthErrorCode.InvalidEmail:
            return 'That is not a valid email address';
        default:
            return 'Something went wrong';
    }
};
