import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import storeConfig from './redux';

import './index.scss';
const PG = PersistGate as any;
ReactDOM.render(
    <Provider store={storeConfig.store}>
        <React.Fragment>
            <PG loading={null} persistor={storeConfig.persistor}>
                <App />
            </PG>
        </React.Fragment>
    </Provider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
