import { Reducer } from 'redux';
import { Action } from 'redux-actions';

import { produceReducer } from '../../common/utils/immer';

import { ACTIONS } from './actions';
import { UserProfile } from './types';

export interface UserState {
    userProfile?: UserProfile;
    profileId?: string;
}

const initialState: UserState = {
    userProfile: undefined,
    profileId: '',
};

const actionHandlers = {
    [ACTIONS.SET_PROFILE]: (draftStore: UserState, action: Action<UserProfile>) => {
        draftStore.userProfile = action.payload;
    },
    [ACTIONS.GET_PROFILE_ID]: (draftStore: UserState, action: Action<string>) => {
        draftStore.profileId = action.payload;
    },
};

export default produceReducer(actionHandlers, initialState) as Reducer<UserState>;
