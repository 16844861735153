import { createAction } from 'redux-actions';

import { Listing, ListingUsersResponse, SetListingParams } from './types';

export const ACTIONS = {
    SET_SEARCH_VALUE: 'SET_SEARCH_VALUE',
    GET_LISTING: 'GET_LISTING',
    GET_LISTING_SUCCESS: 'GET_LISTING_SUCCESS',
    SET_LISTING: 'SET_LISTING',
    SET_LISTING_SUCCESS: 'SET_LISTING_SUCCESS',
    SET_USER_LISTING: 'SET_USER_LISTING',
};

export const setSearchValueListing = createAction<string | undefined>(ACTIONS.SET_SEARCH_VALUE);
export const getListing = createAction<string>(ACTIONS.GET_LISTING);
export const setListing = createAction<SetListingParams>(ACTIONS.SET_LISTING);
export const getListingSuccess = createAction<Listing | undefined>(ACTIONS.GET_LISTING_SUCCESS);
export const setListingSuccess = createAction<Listing>(ACTIONS.SET_LISTING_SUCCESS);
export const setUserListing = createAction<ListingUsersResponse>(ACTIONS.SET_USER_LISTING);
