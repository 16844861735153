import { createAction } from 'redux-actions';

import { Product } from './types';

export const ACTIONS = {
    GET_PRICES: 'GET_PRICES',
    GET_PRICES_SUCCESS: 'GET_PRICES_SUCCESS',
};

export const getPrices = createAction<boolean | undefined>(ACTIONS.GET_PRICES);
export const getPricesSuccess = createAction<Product>(ACTIONS.GET_PRICES_SUCCESS);
