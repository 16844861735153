import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { getListing } from './redux/listing/actions';
import firebase, { firebaseConfig } from './common/services/firebase';
import { getLoadableHelper } from './common/utils/loadableRoute';
import { route } from './common/constants/routes';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import { setLoading, setLocations, setServices } from './redux/common/actions';
import { GlobalState } from './redux';
import { getPrices } from './redux/payment/actions';
import { setUserProfile } from './redux/user/actions';
import { iframeFontUrl } from './common/constants/app';
import { setAuthMessage } from './redux/auth/actions';
import { NSelectItem } from './redux/common/types';
import './App.scss';
import { UserType } from './redux/user/types';

const LandingPage = getLoadableHelper(() => import('./pages/landing/LandingPage'));
const HomePage = getLoadableHelper(() => import('./pages/home/Home'));
const LoginPage = getLoadableHelper(() => import('./pages/login/Login'));
const SignUpPage = getLoadableHelper(() => import('./pages/sign-up/SignUp'));
const SignUpOptions = getLoadableHelper(() => import('./pages/signup-options/SignUpOptions'));
const SetupProfilePage = getLoadableHelper(() => import('./pages/change-profile/ChangeProfile'));
const ForgotPasswordPage = getLoadableHelper(() => import('./pages/forgot-password/ForgotPassword'));
const ChangePasswordPage = getLoadableHelper(() => import('./pages/change-password/ChangePassword'));
const Payment = getLoadableHelper(() => import('./pages/payment/Payment'));
const SetUpPlan = getLoadableHelper(() => import('./pages/setup-plan/SetUpPlan'));
const SuccessPage = getLoadableHelper(() => import('./pages/success/Success'));

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY!);

const App: React.FunctionComponent = (): React.ReactElement => {
    const dispatch = useDispatch();
    const loading = useSelector((state: GlobalState) => state.common.loading);
    const isIndividual = useSelector((state: GlobalState) => state.authConfig.isIndividual);
    const isHideErrorMessage = useSelector((state: GlobalState) => state.authConfig.isHideErrorMessage);

    const [authenticated, setAuthenticated] = useState(false);
    useEffect(() => {
        getServiceList();
        getLocationList();
    }, []);
    const getServiceList = async () => {
        const servicesResponse = await firebase.getServices();
        dispatch(setServices(servicesResponse));
    };

    const getLocationList = async () => {
        const locationsResponse = await firebase.getLocations();
        dispatch(setLocations(locationsResponse as NSelectItem[]));
    };

    const handleError = () => {
        dispatch(setAuthMessage('This is an Individual account. Please download the Auslaw Concierge App to login.'));
    };

    const getAuthorized = async () => {
        try {
            // Retrieve fỉrebase auth status once app loading
            const { uid, userProfile } = await firebase.getAuthStateChanged(handleError, isHideErrorMessage);
            if (uid && userProfile) {
                if (userProfile.userType === UserType.Organisation) {
                    dispatch(setUserProfile(userProfile));
                    dispatch(getListing(uid));
                }
            }
        } catch (error) {
        } finally {
            setAuthenticated(true);
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        getAuthorized();
        dispatch(getPrices(isIndividual));
    }, [isIndividual]);

    if (!authenticated) return <LoadingScreen loading />;

    return (
        <div className="App">
            <ToastContainer />
            <LoadingScreen loading={loading} />
            <Elements
                stripe={stripePromise}
                options={{
                    fonts: [{ cssSrc: iframeFontUrl }],
                }}
            >
                <BrowserRouter>
                    <Switch>
                        <Route component={HomePage} path={route.HOME} />
                        <Route exact component={LoginPage} path={route.LOGIN} />
                        <Route exact component={LandingPage} path={route.LANDING} />
                        <Route exact component={SignUpPage} path={route.SIGN_UP} />
                        <Route exact component={SignUpOptions} path={route.SIGN_UP_OPTIONS} />
                        <Route exact component={SetupProfilePage} path={route.SETUP_PROFILE} />
                        <Route exact component={ForgotPasswordPage} path={route.FORGOT_PASSWORD} />
                        <Route exact component={ChangePasswordPage} path={route.CHANGE_PASSWORD} />
                        <Route exact component={Payment} path={route.PAYMENT} />
                        <Route exact component={SetUpPlan} path={route.SETUP_PLAN} />
                        <Route exact component={SuccessPage} path={route.SUCCESS} />
                        <Redirect to={route.HOME} />
                    </Switch>
                </BrowserRouter>
            </Elements>
        </div>
    );
};

export default App;
