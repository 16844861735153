import { Plan } from '../payment/types';

export enum UserType {
    Individual = 'individual',
    Organisation = 'organisation',
}

interface UserProfile {
    name: string;
    email: string;
    userType: UserType;
    isDoyles?: boolean;
    phone?: string;
    plan?: Plan;
    parentProfileId?: string;
    dateCreated?: string;
    isIndividual?: boolean;
}

export type { UserProfile };
