import React from 'react';
import Modal from 'react-modal';

import { styles } from '../../common/constants/variables';
import Loading, { LoadingSize } from '../Loading/Loading';
import './LoadingScreenStyles.scss';

const LoadingScreen: React.FunctionComponent<{ loading: boolean }> = ({ loading }) => {
    Modal.setAppElement('#root');

    const customLoadingStyles = {
        overlay: {
            background: styles.$primary,
            opacity: '0.72',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '0px',
            borderRadius: '8px',
            border: 'none',
            background: 'transparent',
        },
    };

    return (
        <Modal isOpen={loading} style={customLoadingStyles} contentLabel="Loading Modal">
            <Loading size={LoadingSize.XL} />
            <div className="loading__desc">Processing</div>
        </Modal>
    );
};

export default LoadingScreen;
