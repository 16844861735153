import { NSelectItem } from '../../redux/common/types';

export const getNameFromMultiIndex = (id: string, items: NSelectItem[], onlyItemName = false, depth?: number) => {
    if (id.length === 0) {
        return '';
    }

    let name = '';
    const split = id.split('.');
    const searchDepth = depth ?? 1;

    const searchId = split.slice(0, searchDepth).join('.');
    const found = items.find((item) => item.id === searchId);
    if (found !== undefined) {
        name = found.name;
        if (found.child !== undefined && split.length > searchDepth) {
            name += ` - ${getNameFromMultiIndex(id, found.child, onlyItemName, searchDepth + 1)}`;
        }
    }
    if (onlyItemName) {
        const endName = name.split('- ');
        return endName[endName.length - 1];
    }
    return name;
};

export const groupItems = (ids?: string[]) => {
    if (!ids) return [];
    const checks: string[] = [];
    const result: Array<string[]> = [];
    ids.map((i) => {
        const prefix = i.split('.')[0];
        !checks.includes(prefix) && checks.push(prefix);
    });
    checks.map((check) => {
        const temp: string[] = [];
        ids.map((i) => {
            const prefix = i.split('.')[0];
            check === prefix && temp.push(i);
        });
        result.push(temp);
    });
    return result;
};

export const flattenItems = (items: string[][]) => {
    let services: string[] = [];
    items.forEach((item) => (services = [...services, ...item]));
    return services;
};

export const getIdItem = (items: NSelectItem[], name: string[], deep: number) => {
    if (items.length === 0) {
        return '';
    }
    let id = '';

    const found = items.find((item) => item.name === name[deep].trim());

    if (found) {
        if (name[deep + 1] && found.child) {
            id = getIdItem(found.child as NSelectItem[], name, deep + 1);
        } else {
            id = found.id;
        }
    }
    return id;
};
