import { Reducer } from 'redux';
import { Action } from 'redux-actions';

import { produceReducer } from '../../common/utils/immer';

import { ACTIONS } from './actions';
import { NSelectItem } from './types';

export interface CommonState {
    loading: boolean;
    services: NSelectItem[];
    locations: NSelectItem[];
}

const initialState: CommonState = {
    loading: true,
    services: [],
    locations: [],
};

const actionHandlers = {
    [ACTIONS.SET_LOADING]: (draftStore: CommonState, action: Action<boolean>) => {
        draftStore.loading = action.payload;
    },
    [ACTIONS.SET_SERVICES]: (draftStore: CommonState, action: Action<NSelectItem[]>) => {
        draftStore.services = action.payload;
    },
    [ACTIONS.SET_LOCATIONS]: (draftStore: CommonState, action: Action<NSelectItem[]>) => {
        draftStore.locations = action.payload;
    },
};

export default produceReducer(actionHandlers, initialState) as Reducer<CommonState>;
