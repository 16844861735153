import { createAction } from 'redux-actions';

export const ACTIONS = {
    CHECK_ROUTE_FROM_DOYLES: 'CHECK_ROUTE_FROM_DOYLES',
    CHECK_ROUTE_NORMAL: 'CHECK_ROUTE_NORMAL',
    CHECK_ROUTE_INDIVIDUAL: 'CHECK_ROUTE_INDIVIDUAL',
    HIDE_ERROR_MESSAGE: 'HIDE_ERROR_MESSAGE',
};

export const isFromDoyles = createAction<boolean>(ACTIONS.CHECK_ROUTE_FROM_DOYLES);
export const isFromLogin = createAction<boolean>(ACTIONS.CHECK_ROUTE_NORMAL);
export const isIndividual = createAction<boolean>(ACTIONS.CHECK_ROUTE_INDIVIDUAL);
export const isHideErrorMessage = createAction<boolean>(ACTIONS.HIDE_ERROR_MESSAGE);
