import { Reducer } from 'redux';
import { Action } from 'redux-actions';

import { produceReducer } from '../../common/utils/immer';

import { ACTIONS } from './actions';

export interface AuthConfigState {
    isFromDoyles: boolean;
    isFromLogin: boolean;
    isIndividual: boolean;
    isHideErrorMessage: boolean;
}

const initialState: AuthConfigState = {
    isFromDoyles: false,
    isIndividual: false,
    isFromLogin: false,
    isHideErrorMessage: true,
};

const actionHandlers = {
    [ACTIONS.CHECK_ROUTE_FROM_DOYLES]: (draftStore: AuthConfigState, action: Action<boolean>) => {
        draftStore.isFromDoyles = action.payload;
    },
    [ACTIONS.CHECK_ROUTE_NORMAL]: (draftStore: AuthConfigState, action: Action<boolean>) => {
        draftStore.isFromLogin = action.payload;
    },
    [ACTIONS.CHECK_ROUTE_INDIVIDUAL]: (draftStore: AuthConfigState, action: Action<boolean>) => {
        draftStore.isIndividual = action.payload;
    },
    [ACTIONS.HIDE_ERROR_MESSAGE]: (draftStore: AuthConfigState, action: Action<boolean>) => {
        draftStore.isHideErrorMessage = action.payload;
    },
};

export default produceReducer(actionHandlers, initialState) as Reducer<AuthConfigState>;
