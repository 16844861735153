import { Action } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import firebase from '../../common/services/firebase';

import { Product } from './types';
import { ACTIONS, getPricesSuccess } from './actions';

function* getPrices(action: Action<boolean>) {
    const isIndividual = action.payload;

    try {
        const product: Product = yield call(() => firebase.getPrices(isIndividual));
        yield put(getPricesSuccess(product));
    } catch (error) {}
}

function* watchAll() {
    yield all([takeEvery(ACTIONS.GET_PRICES, getPrices)]);
}

export default watchAll();
