import { all, call, put, takeEvery } from 'redux-saga/effects';
import { Action } from 'redux-actions';

import firebase from '../../common/services/firebase';
import { route } from '../../common/constants/routes';
import { setLoading } from '../common/actions';

import { ACTIONS, getListingSuccess, setListingSuccess } from './actions';
import { Listing, SetListingParams } from './types';

function* getListing(action: Action<string>) {
    try {
        const listing: Listing | undefined = yield call(() => firebase.getListing(action.payload));
        yield put(getListingSuccess(listing));
    } catch (error) {}
}

function* setOrgListing(action: Action<SetListingParams>) {
    try {
        const listing: Listing | undefined = yield call(() =>
            firebase.saveListing(action.payload.listing, true, action.payload.file),
        );

        if (listing) {
            yield put(setListingSuccess(listing));
            action.payload.history.push(action.payload.isSetUpProfile ? route.SUCCESS : route.ORGANISATION_PROFILE);
            yield put(setLoading(false));
        }
    } catch (error) {
        yield put(setLoading(false));
    }
}

function* watchAll() {
    yield all([takeEvery(ACTIONS.GET_LISTING, getListing), takeEvery(ACTIONS.SET_LISTING, setOrgListing)]);
}

export default watchAll();
