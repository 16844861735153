import dayjs from 'dayjs';
import app from 'firebase/app';
import { debounce, omit } from 'lodash';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import { TableConfig } from '../../components/Table/Table';
import { InvitationEmailData, NSelectItem } from '../../redux/common/types';
import { Listing, ListingUsersResponse } from '../../redux/listing/types';
import { PaymentDetails, PaymentResults, Product } from '../../redux/payment/types';
import { UserProfile, UserType } from '../../redux/user/types';
import { FirebaseCollections } from '../constants/enum';
import { route, unProtectedRoutes } from '../constants/routes';
import { FirebaseFunction } from '../helpers/firebaseHelper';
import { mergeObject } from '../utils/convert';

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_ID,
    siteKeyCaptcha: process.env.REACT_APP_SITE_KEY,
};

// TODO: fix bug env

// export const firebaseConfig = {
//     apiKey: 'AIzaSyAkSZaQwD46u_4MFgm7CwbfaOgmouq-AFU',
//     authDomain: 'dev-auslaw-concierge.firebaseapp.com',
//     projectId: 'dev-auslaw-concierge',
//     storageBucket: 'dev-auslaw-concierge.appspot.com',
//     messagingSenderId: '540886230384',
//     appId: '1:540886230384:web:a08b10c9de119ddf839bb5',
//     measurementId: 'G-C35748Y3JT',
//     siteKeyCaptcha: '6LdWttYpAAAAAMhA70ovmGeUsJKYbYYElPmIvQs1',
// };

class Firebase {
    private auth: app.auth.Auth;
    private db: app.firestore.Firestore;
    private storage: app.storage.Storage;
    private firebaseFn: app.functions.Functions;
    constructor() {
        app.initializeApp(firebaseConfig);
        this.auth = app.auth();
        this.db = app.firestore();
        this.storage = app.storage();
        this.firebaseFn = app.app().functions('australia-southeast1');
    }

    // - - - Firebase Auth - - -
    signUp(data: { email: string; password: string }) {
        return this.auth.createUserWithEmailAndPassword(data.email, data.password);
    }

    currentUser = () => this.auth.currentUser;

    signIn = (email: string, password: string) => this.auth.signInWithEmailAndPassword(email, password);

    signOut = () => this.auth.signOut();

    sendResetPassword = async (email: string): Promise<void> => {
        await this.firebaseFn.httpsCallable(FirebaseFunction.requestResetPasswordOnWeb)(email);
    };

    verifyResetPasswordCode = async (actionCode: string): Promise<boolean> => {
        try {
            const email = await this.auth.verifyPasswordResetCode(actionCode);
            if (email) return true;
            return false;
        } catch (error) {
            return false;
        }
    };

    confirmResetPassword = async (actionCode: string, newPassword: string) => {
        await this.auth.confirmPasswordReset(actionCode, newPassword);
    };

    getAuthStateChanged = (handleError: () => void, isHideErrorMessage: boolean) => {
        return new Promise<{ uid: string; userProfile: UserProfile }>((resolve) => {
            this.auth.onAuthStateChanged(
                debounce(async (user) => {
                    const userProfile = user?.uid && (await this.getProfile(user?.uid));
                    const unProtectedRoutesForInd = [
                        ...unProtectedRoutes,
                        route.SETUP_PLAN,
                        route.SETUP_PROFILE,
                        route.PAYMENT,
                    ];

                    /* redirect to login when user is not organisation */
                    if (userProfile && userProfile?.userType === UserType.Individual) {
                        if (!isHideErrorMessage) handleError();
                        if (!unProtectedRoutesForInd.includes(window.location.pathname)) return this.signOut();
                    }

                    if (!user?.uid && !unProtectedRoutes.includes(window.location.pathname)) {
                        window.location.href = route.LOGIN;
                    }

                    if (
                        user?.uid &&
                        unProtectedRoutes.includes(window.location.pathname) &&
                        userProfile?.userType === UserType.Organisation
                    ) {
                        window.location.href = route.HOME;
                    }
                    resolve({ uid: user?.uid, userProfile });
                }, 400),
            );
        });
    };

    // - - - Firebase FireStore - - -
    // Users and Organisation
    setProfile(docId: string, data: UserProfile) {
        return this.db.collection(FirebaseCollections.Profiles).doc(docId).set(data);
    }

    getProfile = async (docId: string): Promise<UserProfile> => {
        const response = await this.db.collection(FirebaseCollections.Profiles).doc(docId).get();
        return response.data() as UserProfile;
    };

    getChildProfile(id: string) {
        return this.db.collection(FirebaseCollections.Profiles).where('parentProfileId', '==', id).get();
    }

    getListing = async (id: string) => {
        try {
            const response = await this.db.collection(FirebaseCollections.Listings).where('profileId', '==', id).get();
            return { ...response.docs[0].data(), id: response.docs[0].id };
        } catch (error) {
            return undefined;
        }
    };

    getListingByEmail = async (profileEmail: string) => {
        let response;
        try {
            response = await this.db
                .collection(FirebaseCollections.Listings)
                .where('profileEmail', '==', profileEmail)
                .get();
            return { ...response.docs[0].data(), id: response.docs[0].id } as Listing;
        } catch (error) {
            return undefined;
        }
    };

    getOrgUsers = async (data: TableConfig): Promise<{ users: ListingUsersResponse[]; total: number } | undefined> => {
        try {
            const response = await this.firebaseFn.httpsCallable(FirebaseFunction.SearchUser)(data);
            return response.data;
        } catch (error) {
            return undefined;
        }
    };

    saveImage = (id: string, file: File) =>
        new Promise<string>((resolve, reject) => {
            // Save to firebase storage
            const ref = this.storage.ref().child('profile_image').child(`${id}`);
            ref.put(file)
                .then(() => {
                    // Return the full storage url
                    ref.getDownloadURL().then((url) => resolve(url));
                })
                .catch(() => {
                    reject(undefined);
                });
        });

    downloadTemplateImportUsers = () =>
        new Promise<string>((resolve) => {
            const ref = this.storage.ref().child('import-user-template.xlsx');
            ref.getDownloadURL().then((url) => {
                resolve(url);
            });
        });

    getExistListing = async (email: string) => {
        const existListing = await this.db.collection(FirebaseCollections.Listings).where('email', '==', email).get();
        if (existListing.size) {
            return existListing.docs[0].id;
        }
        return undefined;
    };

    getExistProfileId = async (email: string) => {
        const existProfile = await this.db.collection(FirebaseCollections.Profiles).where('email', '==', email).get();
        if (existProfile.size) {
            return existProfile.docs[0].id;
        }
        return undefined;
    };

    getProfileByEmail = async (email: string) => {
        const existProfile = await this.db.collection(FirebaseCollections.Profiles).where('email', '==', email).get();
        if (existProfile.size) {
            return existProfile.docs[0].data();
        }
        return undefined;
    };

    saveListing = async (listing: Listing, isOrg: boolean, file?: File) => {
        const listingDoc = this.db.collection(FirebaseCollections.Listings).doc(listing.id);

        console.log(listing);
        const data: Listing = isOrg
            ? {
                  ...listing,
                  isOrg,
                  profileId: this.currentUser()?.uid,
              }
            : {
                  ...listing,
                  isOrg,
              };

        let mergedData: Listing;
        if (listing.id) {
            data.dateModified = dayjs().toISOString();
            const existListing = await listingDoc.get();
            mergedData = mergeObject(existListing.data() as Listing, data) as Listing;
        } else {
            data.dateCreated = dayjs().toISOString();
            mergedData = { ...data };
        }

        // If we need to upload an image
        if (file) {
            //CT: Note - this had been implemented but it is entirely unsure why
            //It never attempts to upload the file, and just sets the imageurl
            //Changing thisso that this code always runs.
            //if (isOrg) {
            if (true) {
                await this.saveImage(listingDoc.id, file).then((url) => {
                    // Insert image url into data
                    mergedData.imageUrl = url;
                });
            } else {
                mergedData.imageUrl = file as unknown as string;
            }
        }

        // Update the listing
        await listingDoc.set(mergedData, { merge: true });

        return {
            ...mergedData,
            id: listingDoc.id,
        } as Listing;
    };

    getUnacceptedInvitationsByEmail = (organisationId: string, email: string) =>
        this.db
            .collection(FirebaseCollections.Invitations)
            .where('organisationId', '==', organisationId)
            .where('accepted', '==', false)
            .where('cancelled', '==', false)
            .where('inviteeEmail', '==', email)
            .get();

    getAlreadyInvitedUserInOrg = (currentOrg: string, email: string) =>
        this.db
            .collection(FirebaseCollections.Profiles)
            .where('parentProfileId', '==', currentOrg)
            .where('email', '==', email)
            .get();

    cancelInvitation = async (organisationId: string, email: string) => {
        const existInvitations = await this.getUnacceptedInvitationsByEmail(organisationId, email);
        const userRef = this.db.collection(FirebaseCollections.Invitations).doc(existInvitations.docs[0].id);
        userRef.update({ cancelled: true });
    };

    unlinkOrganisation = async (organisationId: string, email: string) => {
        const data = await Promise.all([
            this.db
                .collection(FirebaseCollections.Invitations)
                .where('organisationId', '==', organisationId)
                .where('inviteeEmail', '==', email)
                .get(),
            this.db.collection(FirebaseCollections.Profiles).where('email', '==', email).get(),
        ]);
        const invitation = data[0].docs[0].data();
        const inviteeProfile = data[1].docs[0].data();
        const invitationId = data[0].docs[0].id;
        const inviteeId = data[1].docs[0].id;
        console.log('📢 [firebase.ts:295]', inviteeId);
        const listingDoc = await this.db
            .collection(FirebaseCollections.Listings)
            .where('profileId', '==', inviteeId)
            .get();
        console.log('📢 [firebase.ts:296]', listingDoc.docs[0].data());
        const listing = listingDoc?.docs?.[0]?.data();
        const listingId = listingDoc?.docs?.[0]?.id;

        listing.planStatus = 'cancelled';
        const updateProfileData: any = omit(data[1].docs[0].data(), ['parentProfileId']);
        if (inviteeProfile?.isRegular && listing) {
            listing.isDoyles = false;
            updateProfileData.isDoyles = false;
        }
        if (!inviteeProfile?.isRegular && inviteeProfile?.isOriginalDoyles) {
            updateProfileData.isDoyles = inviteeProfile?.isOriginalDoyles;
        }
        // Unlink invitation
        const userRef = this.db.collection(FirebaseCollections.Invitations).doc(invitationId);
        await userRef.set(
            omit({ ...data[0].docs[0].data(), accepted: false, cancelled: true }, ['inviterName', 'organisationId']),
        );
        // Unlink listing
        if (listingId) {
            const listingRef = await this.db.collection(FirebaseCollections.Listings).doc(listingId);
            await listingRef.set(listing);
        }

        // Unlink profile
        const profileRef = this.db.collection(FirebaseCollections.Profiles).doc(inviteeId);
        await profileRef.set(updateProfileData);
    };

    getAlreadyBelongToAnOrg = (email: string) =>
        this.db
            .collection(FirebaseCollections.Invitations)
            .where('accepted', '==', true)
            .where('inviteeEmail', '==', email)
            .get();

    getInvitationDetail = async (inviteeEmail: string) => {
        const invitations = await this.db
            .collection(FirebaseCollections.Invitations)
            .where('organisationId', '==', this.currentUser()?.uid)
            .where('inviteeEmail', '==', inviteeEmail)
            .where('cancelled', '==', false)
            .where('accepted', '==', false)
            .get();

        if (invitations.size) {
            return invitations.docs[0].id;
        }

        return undefined;
    };

    addInvitation = async (inviteeEmail: string, inviteeName: string, organisationId: string, inviterName: string) => {
        // generate a 4 digit code for confirmation later
        let code = Math.floor(9999 * Math.random()).toString();
        // we appear to be missing .padStart()...
        while (code.length < 4) code = '0' + code;

        // Updated invitation document which have same inviteeEmail
        const invitationCancelled = await this.db
            .collection(FirebaseCollections.Invitations)
            .where('organisationId', '==', this.currentUser()?.uid)
            .where('inviteeEmail', '==', inviteeEmail)
            .where('cancelled', '==', true)
            .get();

        const doc = {
            code,
            inviteeEmail,
            inviteeName,
            organisationId,
            inviterName,
            accepted: false,
            cancelled: false,
        };
        const docRef = this.db
            .collection(FirebaseCollections.Invitations)
            .doc(
                invitationCancelled.docs[0] && invitationCancelled.docs[0].id
                    ? invitationCancelled.docs[0].id
                    : undefined,
            );
        docRef.set(doc);

        return docRef.id;
    };

    sendInvitationEmail = (to: string, data: InvitationEmailData) => {
        const doc = this.db.collection(FirebaseCollections.MailToSend).doc();
        doc.set({
            to,
            template: {
                name: 'InvitationEmail',
                data,
            },
        });
    };

    getLocations = async () => {
        try {
            const locations = await this.db.collection(FirebaseCollections.Locations).get();
            return [...locations.docs.map((location) => location.data())];
        } catch (error) {
            return [];
        }
    };

    getServices = async (): Promise<NSelectItem[]> => {
        try {
            const services = await this.db.collection(FirebaseCollections.Services).get();
            return [
                ...services.docs.map((service) => {
                    return {
                        name: service.id,
                        id: service.data().id,
                        child: service.data().child,
                    };
                }),
            ];
        } catch (error) {
            return [];
        }
    };

    getPrices = async (isIndividual: boolean) => {
        const type = isIndividual ? 'individual' : 'organisation';

        const response = await this.db.collection(FirebaseCollections.Prices).where('id', '==', type).get();
        return response.docs[0].data() as Product;
    };

    doPayment = async (tokenId: string, details: PaymentDetails): Promise<PaymentResults> => {
        const response = await this.firebaseFn.httpsCallable(FirebaseFunction.PayWithStripe)({
            ...details,
            stripeToken: tokenId,
        });
        return response.data;
    };

    cancelSubscription = async (subscriptionId: string): Promise<UserProfile> => {
        const response = await this.firebaseFn.httpsCallable(FirebaseFunction.CancelSubscription)({ subscriptionId });
        return response.data;
    };

    countMemberOfOrganisation = async (): Promise<number> => {
        const response = await this.db
            .collection(FirebaseCollections.Invitations)
            .where('organisationId', '==', this.currentUser()?.uid)
            .where('cancelled', '==', false)
            .get();
        return response.docs.length;
    };
}

export default new Firebase();
