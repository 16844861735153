import { createAction } from 'redux-actions';

import { NSelectItem } from './types';

export const ACTIONS = {
    SET_LOADING: 'SET_LOADING',
    SET_SERVICES: 'SET_SERVICES',
    SET_LOCATIONS: 'SET_LOCATIONS',
};

export const setLoading = createAction<boolean>(ACTIONS.SET_LOADING);
export const setServices = createAction<NSelectItem[]>(ACTIONS.SET_SERVICES);
export const setLocations = createAction<NSelectItem[]>(ACTIONS.SET_LOCATIONS);
