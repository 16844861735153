import { isString } from 'formik';
import * as XLSX from 'xlsx';

import { InviteeFields, NSelectItem, ObjectType } from '../../redux/common/types';
import {
    excelHeaderColumnMapping,
    servicesSeparateSymbol,
    stateList,
    subServicesSeparateSymbol,
} from '../constants/app';
import { InviteeKeysEnum } from '../constants/enum';

import { getIdItem } from './listing';

interface ConvertResponse {
    data: InviteeFields[];
    errors: { state?: string[]; services?: string[]; locations?: string[] };
}

export const convertInviteesXlsxToArray = async (
    selectedFile: File,
    serviceList: NSelectItem[],
): Promise<ConvertResponse> => {
    if (!selectedFile) {
        return { data: [], errors: {} };
    }

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        const stateValues = stateList.map((state) => state.value);
        reader.onload = async (e: ProgressEvent<FileReader>) => {
            const bstr = e.target && e.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            const jsonData: any[] = XLSX.utils.sheet_to_json(ws);

            const result: ConvertResponse = { data: [], errors: {} };
            const headerColumns = (jsonData[0] && Object.keys(jsonData[0])) || [];
            if (!headerColumns.length) {
                reject(Error('Please insert data to excel template'));
            }
            // Validate header Column of Excel file
            headerColumns.forEach((header: string) => {
                if (!excelHeaderColumnMapping[header]) {
                    reject(Error('The upload does not match our template'));
                }
            });

            for (let i = 0; i < jsonData.length; i++) {
                const serviceIds: string[] = [];
                const inviteeRow = { ...jsonData[i] };

                // Service Validator and formatter
                if (inviteeRow && inviteeRow['Services']) {
                    const services = inviteeRow['Services'].split(servicesSeparateSymbol);
                    for (const service of services) {
                        if (service) {
                            const id = getIdItem(serviceList, service.split(subServicesSeparateSymbol), 0);
                            if (id) {
                                serviceIds.push(id);
                            } else {
                                if (Array.isArray(result.errors?.services)) {
                                    result.errors?.services?.push(inviteeRow['Email']);
                                } else {
                                    result.errors.services = [inviteeRow['Email']];
                                }
                            }
                        }
                    }
                }

                inviteeRow['Services'] = serviceIds;

                // State Validator
                if (inviteeRow && inviteeRow['State'] && !stateValues.includes(inviteeRow['State'].toString().trim())) {
                    if (Array.isArray(result.errors?.state)) {
                        result.errors?.state?.push(inviteeRow['Email']);
                    } else {
                        result.errors.state = [inviteeRow['Email']];
                    }
                }

                const inviteeObj: InviteeFields = {
                    name: '',
                    email: '',
                    phone: '',
                    mobile: '',
                    services: [],
                    bio: '',
                    accreditations: '',
                    streetAddress: '',
                    suburb: '',
                    postcode: '',
                    state: '',
                };

                for (const key in inviteeRow) {
                    const row = inviteeRow[key as InviteeKeysEnum];
                    inviteeObj[excelHeaderColumnMapping[key] as InviteeKeysEnum] =
                        row && isString(row) ? row.trim() : Array.isArray(row) ? row : row.toString();
                }
                result.data.push(inviteeObj);
            }
            resolve(result);
        };

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.readAsBinaryString(selectedFile as File);
    });
};

export const mergeObject = (firstObj: ObjectType, secondObj: ObjectType) => {
    const keyValues = Object.keys(secondObj);
    keyValues.forEach((key) => {
        if (Array.isArray(secondObj[key]) && !secondObj[key].length) {
            secondObj[key] = firstObj[key] || [];
        } else if (!secondObj[key]) {
            secondObj[key] = firstObj[key] || '';
        }
    });
    return secondObj;
};
