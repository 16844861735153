import { applyMiddleware, combineReducers, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import { all } from 'redux-saga/effects';
import storage from 'redux-persist/lib/storage';

// Import all reducers here

import userReducer, { UserState } from './user/reducer';
import listingReducer, { ListingState } from './listing/reducer';
import commonReducer, { CommonState } from './common/reducer';
import paymentReducer, { PaymentState } from './payment/reducer';
import authConfigReducer, { AuthConfigState } from './authRouterConfig/reducer';
import authReducer, { AuthState } from './auth/reducer';
// Import all sagas here
import userSagas from './user/sagas';
import listingSagas from './listing/sagas';
import paymentSagas from './payment/sagas';

export interface GlobalState {
    user: UserState;
    listing: ListingState;
    common: CommonState;
    payment: PaymentState;
    auth: AuthState;
    authConfig: AuthConfigState;
}

const persistListingConfig: PersistConfig<ListingState> = {
    key: 'listing',
    storage,
    blacklist: ['searchValue'],
};

const persistCommonConfig: PersistConfig<CommonState> = {
    key: 'common',
    storage,
    blacklist: ['loading', 'services', 'locations'],
};

export const combinedReducer = combineReducers({
    auth: authReducer,
    authConfig: authConfigReducer,
    user: userReducer,
    listing: persistReducer(persistListingConfig, listingReducer),
    common: persistReducer(persistCommonConfig, commonReducer),
    payment: paymentReducer,
});

export const rootSaga = function* (): any {
    yield all([userSagas, listingSagas, paymentSagas]);
};

function configureStore() {
    // create the redux-saga middleware
    const sagaMiddleware = createSagaMiddleware();

    const persistConfig = {
        key: 'root',
        storage,
        blacklist: ['listing', 'common', 'auth'],
    };

    const persistedReducer = persistReducer(persistConfig, combinedReducer);

    const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));

    // Don't forget to run the root saga, and return the store object.
    sagaMiddleware.run(rootSaga);
    const persistor = persistStore(store);

    return { store, persistor };
}

export default configureStore();
