export enum AuthErrorCode {
    InvalidEmail = 'auth/invalid-email',
    AlreadyInUse = 'auth/email-already-in-use',
    NoUserFound = 'auth/user-not-found',
    WrongPassword = 'auth/wrong-password',
}

export enum MessageFileUploaded {
    success = 'File successfully uploaded',
    failure = 'Failure, please try again',
}

export enum FirebaseCollections {
    // User
    Profiles = 'profiles',

    Favorites = 'favorites',

    // Calendar
    SelectedCalendars = 'selectedCalendars',
    CalendarEvents = 'calendarEvents',

    // Listings
    Listings = 'listings',

    // Static Lists
    Services = 'services',
    Specialisations = 'specialisations',
    Locations = 'locations',
    Prices = 'prices',

    // Services, specialisations, and locations requested by users
    ServiceRequests = 'serviceRequests',

    // Handle invitations
    Invitations = 'invitations',

    // Sending mail
    MailToSend = 'mailToSend',

    // Stripe
    Pricing = 'pricing',
}

// MODELS
export enum SortType {
    ASC = 'asc',
    DESC = 'desc',
}

export enum UserBadgeStatus {
    Active = 'Active',
    Pending = 'Pending',
    Canceled = 'Cancelled',
}

export enum ProfileTabs {
    users = 'Users',
    organisation = 'Organisation Profile',
}

export enum InviteTabs {
    single = 'Invite Single User',
    multiple = 'Invite Multiple User (Bulk)',
}

export enum SubHeaderType {
    ProfileHeader = 'ProfileHeader',
    InvitationHeader = 'InvitationHeader',
}

export enum InviteeKeysEnum {
    NAME = 'name',
    EMAIL = 'email',
    PHONE = 'phone',
    MOBILE = 'mobile',
    BIO = 'bio',
    ACCREDITATIONS = 'accreditations',
    STREET_ADDRESS = 'streetAddress',
    SUBURB = 'suburb',
    POSTCODE = 'postcode',
    STATE = 'state',
    SERVICES = 'services',
}
