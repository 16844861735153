export const stateList = [
    {
        value: 'ACT',
        label: 'ACT',
    },
    {
        value: 'NSW',
        label: 'NSW',
    },
    {
        value: 'NT',
        label: 'NT',
    },
    {
        value: 'QLD',
        label: 'QLD',
    },
    {
        value: 'SA',
        label: 'SA',
    },
    {
        value: 'TAS',
        label: 'TAS',
    },
    {
        value: 'VIC',
        label: 'VIC',
    },
    {
        value: 'WA',
        label: 'WA',
    },
];

export const inviteSubHeaderTabs = [
    {
        title: 'Invite Single User',
    },
    {
        title: 'Invite Multiple User (Bulk)',
    },
];

export const excelHeaderColumnMapping: {
    [key: string]: string;
} = {
    Name: 'name',
    Email: 'email',
    Mobile: 'mobile',
    Phone: 'phone',
    'User Bio': 'bio',
    Accreditations: 'accreditations',
    Street: 'streetAddress',
    Suburb: 'suburb',
    Postcode: 'postcode',
    State: 'state',
    Services: 'services',
};

export const servicesSeparateSymbol = '*';

export const subServicesSeparateSymbol = '-';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const BOUNDARY_UNLIMITED_PLAN = 21;

export const iframeFontUrl = 'https://fonts.googleapis.com/css2?family=Barlow:wght@300;400&display=swap';
