export const route = {
    LANDING: '/doyles',
    HOME: '/home',
    LOGIN: '/login',
    SIGN_UP: '/sign-up/:type',
    SIGN_UP_OPTIONS: '/sign-up-options',
    FORGOT_PASSWORD: '/forgot-password',
    CHANGE_PASSWORD: '/change-password',
    PROFILE: '/profile',
    USERS: '/home/user-list',
    ORGANISATION_PROFILE: '/home/organisation-profile',
    SETUP_PROFILE: '/setup-profile',
    SETUP_PLAN: '/setup-plan',
    SUCCESS: '/success',
    INVITE_USER: '/home/invite-user',
    INVITE_USER_SINGLE: '/home/invite-user/single',
    INVITE_USER_MULTIPLE: '/home/invite-user/multiple',
    MULTIPLE_INVITEES: '/home/invite-user/multiple-invitees',
    USER_PROFILE: '/home/user-profile',
    USER_CHANGE_PROFILE: '/home/user-change-profile',
    ORGANISATION_CHANGE_PROFILE: '/home/organisation-change-profile',
    PAYMENT: '/payment',
};

export const unProtectedRoutes = [
    route.LANDING,
    route.LOGIN,
    route.SIGN_UP_OPTIONS,
    route.CHANGE_PASSWORD,
    route.FORGOT_PASSWORD,
    route.SUCCESS,
    '/sign-up/organization',
    '/sign-up/individual',
];

export const activateNavbarRoutes = [
    route.USERS,
    route.USER_PROFILE,
    route.USER_CHANGE_PROFILE,
    route.ORGANISATION_PROFILE,
    route.ORGANISATION_CHANGE_PROFILE,
];

export const activateUserRouter = [route.USERS, route.USER_PROFILE, route.USER_CHANGE_PROFILE];

export const unValidateUserRouter = [route.INVITE_USER_MULTIPLE, route.INVITE_USER_SINGLE, route.INVITE_USER];

export const editProfileRouters = [
    route.USER_CHANGE_PROFILE,
    route.INVITE_USER_SINGLE,
    route.SETUP_PROFILE,
    route.ORGANISATION_CHANGE_PROFILE,
];
