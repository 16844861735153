import { createAction } from 'redux-actions';

import { UserProfile } from './types';

export const ACTIONS = {
    SET_PROFILE: 'SET_PROFILE',
    GET_PROFILE_ID: 'GET_PROFILE_ID',
};

export const setUserProfile = createAction<UserProfile>(ACTIONS.SET_PROFILE);
export const getProfileId = createAction<string | undefined>(ACTIONS.GET_PROFILE_ID);
