import { Reducer } from 'redux';
import { Action } from 'redux-actions';

import { produceReducer } from '../../common/utils/immer';

import { Listing, ListingUsersResponse } from './types';
import { ACTIONS } from './actions';

export interface ListingState {
    searchValue: string;
    orgListing?: Listing;
    userListing?: ListingUsersResponse;
}

const initialState: ListingState = {
    searchValue: '',
    orgListing: undefined,
    userListing: undefined,
};

const actionHandlers = {
    [ACTIONS.SET_SEARCH_VALUE]: (draftStore: ListingState, action: Action<string>) => {
        draftStore.searchValue = action.payload;
    },

    [ACTIONS.GET_LISTING_SUCCESS]: (draftStore: ListingState, action: Action<Listing>) => {
        draftStore.orgListing = action.payload;
    },

    [ACTIONS.SET_LISTING_SUCCESS]: (draftStore: ListingState, action: Action<Listing>) => {
        draftStore.orgListing = action.payload;
    },

    [ACTIONS.SET_USER_LISTING]: (draftStore: ListingState, action: Action<ListingUsersResponse>) => {
        draftStore.userListing = action.payload;
    },
};

export default produceReducer(actionHandlers, initialState) as Reducer<ListingState>;
